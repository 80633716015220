import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

const Imprint = () => {
  return (
    <Layout>
      <Helmet title={'Tom Beyer: Imprint'} />
      <h1 className="pageTitle">Imprint</h1>
      <p>
        <strong>Angaben gemäß § 5 TMG:</strong>
      </p>
      <p>Thomas Beyer Wildbachstraße 17a 84036 Landshut</p>
      <h2 id="kontakt">Kontakt:</h2>
      <p>Telefon: +49 176 10303495 E-Mail: beyer.thomas@gmail.com</p>
      <h2 id="streitschlichtung">Streitschlichtung</h2>
      <p>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:
      </p>
      <p>
        <a href="https://ec.europa.eu/consumers/odr">
          https://ec.europa.eu/consumers/odr
        </a>
      </p>
      <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <h2 id="haftung-für-inhalte">Haftung für Inhalte</h2>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
        diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
        bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
        übermittelte oder gespeicherte fremde Informationen zu überwachen oder
        nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
        hinweisen.
      </p>
      <p>
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </p>
      <h2 id="haftung-für-links">Haftung für Links</h2>
      <p>
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      </p>
      <p>
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
        ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
        entfernen.
      </p>
      <h2 id="urheberrecht">Urheberrecht</h2>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      </p>
      <p>
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </p>
      <p>
        Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
      </p>
      <h1 id="datenschutzerklärung">Datenschutzerklärung</h1>
      <h2 id="datenschutz">Datenschutz</h2>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p>
        Die Nutzung unserer Website ist in der Regel ohne Angabe
        personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
        freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
        Zustimmung nicht an Dritte weitergegeben.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <h2 id="cookies">Cookies</h2>
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert.
      </p>
      <p>
        Die meisten der von uns verwendeten Cookies sind so genannte
        „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
        gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie
        diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
        nächsten Besuch wiederzuerkennen.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browser
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <h2 id="server-log-files">Server-Log-Files</h2>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log Files, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </p>
      <p>Browsertyp und Browserversion</p>
      <p>verwendetes Betriebssystem</p>
      <p>Referrer URL</p>
      <p>Hostname des zugreifenden Rechners</p>
      <p>Uhrzeit der Serveranfrage</p>
      <p>
        Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
        Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen,
        wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt
        werden.
      </p>
      <h2 id="kontaktformular">Kontaktformular</h2>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <h2 id="newsletterdaten">Newsletterdaten</h2>
      <p>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
        benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche
        uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
        E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden
        sind. Weitere Daten werden nicht erhoben. Diese Daten verwenden wir
        ausschließlich für den Versand der angeforderten Informationen und geben
        sie nicht an Dritte weiter.
      </p>
      <p>
        Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse
        sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
        widerrufen, etwa über den “Austragen”-Link im Newsletter.
      </p>
      <h2 id="google-analytics">Google Analytics</h2>
      <p>
        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
        Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View,
        CA 94043, USA.
      </p>
      <p>
        Google Analytics verwendet so genannte “Cookies”. Das sind Textdateien,
        die auf Ihrem Computer gespeichert werden und die eine Analyse der
        Benutzung der Website durch Sie ermöglichen. Die durch den Cookie
        erzeugten Informationen über Ihre Benutzung dieser Website werden in der
        Regel an einen Server von Google in den USA übertragen und dort
        gespeichert.
      </p>
      <h2 id="ip-anonymisierung">IP-Anonymisierung</h2>
      <p>
        Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
        Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten
        der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
        über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA
        gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
        von Google in den USA übertragen und dort gekürzt. Im Auftrag des
        Betreibers dieser Website wird Google diese Informationen benutzen, um
        Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten zusammenzustellen und um weitere mit der
        Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
        Analytics von Ihrem Browser übermittelte IP- Adresse wird nicht mit
        anderen Daten von Google zusammengeführt.
      </p>
      <h2 id="browser-plugin">Browser Plugin</h2>
      <p>
        Sie können die Speicherung der Cookies durch eine entsprechende
        Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website vollumfänglich werden nutzen können. Sie
        können darüber hinaus die Erfassung der durch den Cookie erzeugten und
        auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
        Google sowie die Verarbeitung dieser Daten durch Google verhindern,
        indem Sie
      </p>
      <p>
        das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
        installieren:
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>
      </p>
      <h2 id="widerspruch-gegen-datenerfassung">
        Widerspruch gegen Datenerfassung
      </h2>
      <p>
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
        indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie
        gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser
        Website verhindert: Google Analytics deaktivieren
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
        finden Sie in der Datenschutzerklärung von Google:{' '}
        <a href="https://support.google.com/analytics/answer/6004245?hl=de">
          https://support.google.com/analytics/answer/6004245?hl=de
        </a>
      </p>
      <h2 id="auftragsdatenverarbeitung">Auftragsdatenverarbeitung</h2>
      <p>
        Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
        abgeschlossen und setzen die strengen Vorgaben der deutschen
        Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
      </p>
      <h2 id="demografische-merkmale-bei-google-analytics">
        Demografische Merkmale bei Google Analytics
      </h2>
      <p>
        Diese Website nutzt die Funktion “demografische Merkmale” von Google
        Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
        Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
        Daten stammen aus interessenbezogener Werbung von Google sowie aus
        Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten
        Person zugeordnet werden. Sie können diese Funktion jederzeit über die
        Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die
        Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch
        gegen Datenerfassung” dargestellt generell untersagen.
      </p>
      <h2 id="etracker">etracker</h2>
      <p>
        Unsere Website nutzt den Analysedienst etracker. Anbieter ist die
        etracker GmbH, Erste Brunnenstraße 1, 20459 Hamburg Germany. Aus den
        Daten können unter einem Pseudonym Nutzungsprofile erstellt werden. Dazu
        können Cookies eingesetzt werden. Bei Cookies handelt es sich um kleine
        Textdateien, die lokal im Zwischenspeicher Ihres Internet-Browsers
        gespeichert werden. Die Cookies ermöglichen es, Ihren Browser wieder zu
        erkennen. Die mit den etracker-Technologien erhobenen Daten werden ohne
        die gesondert erteilte Zustimmung des Betroffenen nicht genutzt,
        Besucher unserer Website persönlich zu identifizieren und werden nicht
        mit personenbezogenen Daten über den Träger des Pseudonyms
        zusammengeführt.
      </p>
      <p>
        Der Datenerhebung und -speicherung können Sie jederzeit mit Wirkung für
        die Zukunft widersprechen. Um einer Datenerhebung und -speicherung Ihrer
        Besucherdaten für die Zukunft zu widersprechen, können Sie unter
        nachfolgendem Link ein Opt-Out-Cookie von etracker beziehen, dieser
        bewirkt, dass zukünftig keine Besucherdaten Ihres Browsers bei etracker
        erhoben und gespeichert werden:
        <a href="https://www.etracker.de/privacy?et=V23Jbb">
          https://www.etracker.de/privacy?et=V23Jbb
        </a>
      </p>
      <p>
        Dadurch wird ein Opt-Out-Cookie mit dem Namen “cntcookie” von etracker
        gesetzt. Bitte löschen Sie diesen Cookie nicht, solange Sie Ihren
        Widerspruch aufrecht erhalten möchten. Weitere Informationen finden Sie
        in den Datenschutzbestimmungen von etracker:{' '}
        <a href="https://www.etracker.com/de/datenschutz.html">
          https://www.etracker.com/de/datenschutz.html
        </a>
      </p>
      <h2 id="matomo">Matomo</h2>
      <p>
        Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo
        verwendet so genannte “Cookies”. Das sind Textdateien, die auf Ihrem
        Computer gespeichert werden und die eine Analyse der Benutzung der
        Website durch Sie ermöglichen. Dazu werden die durch den Cookie
        erzeugten Informationen über die Benutzung dieser Website auf unserem
        Server gespeichert. Die IP-Adresse wird vor der Speicherung
        anonymisiert.
      </p>
      <p>
        Die durch den Cookie erzeugten Informationen über die Benutzung dieser
        Website werden nicht an Dritte weitergegeben. Sie können die Speicherung
        der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
        verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
        gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
        werden nutzen können.
      </p>
      <p>
        Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht einverstanden
        sind, können Sie die Speicherung und Nutzung hier deaktivieren. In
        diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt, der
        verhindert, dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies
        löschen, hat dies zur Folge, dass auch das Matomo Opt-Out-Cookie
        gelöscht wird. Das Opt-Out muss bei einem erneuten Besuch unserer Seite
        wieder aktiviert werden.
      </p>
      <h2 id="facebook-plugins-like-button">Facebook-Plugins (Like-Button)</h2>
      <p>
        Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook,
        Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA,
        integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder
        dem “Like-Button” (“Gefällt mir”) auf unserer Seite. Eine Übersicht über
        die Facebook-Plugins finden Sie hier:{' '}
        <a href="https://developers.facebook.com/docs/plugins/">
          https://developers.facebook.com/docs/plugins/
        </a>
        .
      </p>
      <p>
        Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
        Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
        Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse
        unsere Seite besucht haben. Wenn Sie den Facebook “Like-Button”
        anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, können
        Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken.
        Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto
        zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
        Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch
        Facebook erhalten. Weitere Informationen hierzu finden Sie in der
        Datenschutzerklärung von Facebook unter{' '}
        <a href="https://de-de.facebook.com/policy.php">
          https://de-de.facebook.com/policy.php
        </a>
        .
      </p>
      <p>
        Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem
        Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
        Facebook-Benutzerkonto aus.
      </p>
      <h2 id="twitter">Twitter</h2>
      <p>
        Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden.
        Diese Funktionen werden angeboten durch die Twitter Inc., 1355 Market
        Street, Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von
        Twitter und der Funktion “Re-Tweet” werden die von Ihnen besuchten
        Websites mit Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt
        gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen
        darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
        der
      </p>
      <p>
        übermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere
        Informationen hierzu finden Sie in der Datenschutzerklärung von Twitter
        unter{' '}
        <a href="https://twitter.com/privacy">https://twitter.com/privacy</a>.
      </p>
      <p>
        Ihre Datenschutzeinstellungen bei Twitter können Sie in den
        Konto-Einstellungen unter:
        <a href="https://twitter.com/account/settings">
          https://twitter.com/account/settings
        </a>{' '}
        ändern.
      </p>
      <h2 id="google">Google+</h2>
      <p>
        Unsere Seiten nutzen Funktionen von Google+. Anbieter ist die Google
        Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
      </p>
      <p>
        Erfassung und Weitergabe von Informationen: Mithilfe der
        Google+-Schaltfläche können Sie Informationen weltweit veröffentlichen.
        Über die Google+-Schaltfläche erhalten Sie und andere Nutzer
        personalisierte Inhalte von Google und unseren Partnern. Google
        speichert sowohl die Information, dass Sie für einen Inhalt +1 gegeben
        haben, als auch Informationen über die Seite, die Sie beim Klicken auf
        +1 angesehen haben. Ihre +1 können als Hinweise zusammen mit Ihrem
        Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in
        Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen auf
        Websites und Anzeigen im Internet eingeblendet werden.
      </p>
      <p>
        Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die
        Google-Dienste für Sie und andere zu verbessern. Um die
        Google+-Schaltfläche verwenden zu können, benötigen Sie ein weltweit
        sichtbares, öffentliches Google-Profil, das zumindest den für das Profil
        gewählten Namen enthalten muss. Dieser Name wird in allen
        Google-Diensten verwendet. In manchen Fällen kann dieser Name auch einen
        anderen Namen ersetzen, den Sie beim Teilen von Inhalten über Ihr
        Google-Konto verwendet haben. Die Identität Ihres Google-Profils kann
        Nutzern angezeigt werden, die Ihre E-Mail-Adresse kennen oder über
        andere identifizierende Informationen von Ihnen verfügen.
      </p>
      <p>
        Verwendung der erfassten Informationen: Neben den oben erläuterten
        Verwendungszwecken werden die von Ihnen bereitgestellten Informationen
        gemäß den geltenden Google-Datenschutzbestimmungen genutzt. Google
        veröffentlicht möglicherweise zusammengefasste Statistiken über die
        +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner weiter,
        wie etwa Publisher, Inserenten oder verbundene Websites.
      </p>
      <h2 id="instagram">Instagram</h2>
      <p>
        Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden.
        Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow
        Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem
        Instagram-Account eingeloggt sind können Sie durch Anklicken des
        Instagram-Buttons die Inhalte unserer Seiten mit Ihrem Instagram-Profil
        verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem
        Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der
        Seiten keine Kenntnis vom Inhalt der u?bermittelten Daten sowie deren
        Nutzung durch Instagram erhalten.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        Instagram:
        <a href="https://instagram.com/about/legal/privacy/">
          https://instagram.com/about/legal/privacy/
        </a>
      </p>
      <h2 id="linkedin">LinkedIn</h2>
      <p>
        Unsere Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter ist die
        LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA.
        Bei jedem Abruf einer unserer Seiten, die Funktionen von LinkedIn
        enthält, wird eine Verbindung zu Servern von LinkedIn aufbaut. LinkedIn
        wird darüber informiert, dass Sie unsere Internetseiten mit Ihrer
        IP-Adresse besucht haben. Wenn Sie den “Recommend-Button” von LinkedIn
        anklicken und in Ihrem Account bei LinkedIn eingeloggt sind, ist es
        LinkedIn möglich, Ihren Besuch auf unserer Internetseite Ihnen und Ihrem
        Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter
        der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
        Nutzung durch LinkedIn haben.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        LinkedIn unter:
        <a href="https://www.linkedin.com/legal/privacy-policy">
          https://www.linkedin.com/legal/privacy-policy
        </a>
      </p>
      <h2 id="pinterest">Pinterest</h2>
      <p>
        Auf unserer Seite verwenden wir Social Plugins des sozialen Netzwerkes
        Pinterest, das von der Pinterest Inc., 808 Brannan Street San Francisco,
        CA 94103-490, USA (“Pinterest”) betrieben wird. Wenn Sie eine Seite
        aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine
        direkte Verbindung zu den Servern von Pinterest her. Das Plugin
        übermittelt dabei Protokolldaten an den Server von Pinterest in die USA.
        Diese Protokolldaten enthalten möglicherweise Ihre IP-Adresse, die
        Adresse der besuchten Websites, die ebenfalls Pinterest-Funktionen
        enthalten, Art und Einstellungen des Browsers, Datum und Zeitpunkt der
        Anfrage, Ihre Verwendungsweise von Pinterest sowie Cookies.
      </p>
      <p>
        Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung und
        Nutzung der Daten durch Pinterest sowie Ihre diesbezüglichen Rechte und
        Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den den
        Datenschutzhinweisen von Pinterest:{' '}
        <a href="https://about.pinterest.com/de/privacy-policy">
          https://about.pinterest.com/de/privacy-policy
        </a>
      </p>
      <h2 id="soundcloud">SoundCloud</h2>
      <p>
        Auf unseren Seiten können Plugins des sozialen Netzwerks SoundCloud
        (SoundCloud Limited, Berners House, 47-48 Berners Street, London W1T
        3NF, Großbritannien.) integriert sein. Die SoundCloud-Plugins erkennen
        Sie an dem SoundCloud-Logo auf den betroffenen Seiten.
      </p>
      <p>
        Wenn Sie unsere Seiten besuchen, wird nach Aktivierung des Plugin eine
        direkte Verbindung zwischen Ihrem Browser und dem SoundCloud-Server
        hergestellt. SoundCloud erhält dadurch die Information, dass Sie mit
        Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den “Like-Button”
        oder “Share-Button” anklicken während Sie in Ihrem SoundCloud-
        Benutzerkonto eingeloggt sind, können Sie die Inhalte unserer Seiten mit
        Ihrem SoundCloud-Profil verlinken und/oder teilen. Dadurch kann
        SoundCloud Ihrem Benutzerkonto den Besuch unserer Seiten zuordnen. Wir
        weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
        Inhalt der übermittelten Daten sowie deren Nutzung durch SoundCloud
        erhalten. Weitere Informationen hierzu finden Sie in der
        Datenschutzerklärung von SoundCloud unter:
        <a href="https://soundcloud.com/pages/privacy">
          https://soundcloud.com/pages/privacy
        </a>
      </p>
      <p>
        Wenn Sie nicht wünschen, dass Soundcloud den Besuch unserer Seiten Ihrem
        SoundCloud- Benutzerkonto zuordnet, loggen Sie sich bitte aus Ihrem
        SoundCloud-Benutzerkonto aus bevor Sie Inhalte des SoundCloud-Plugins
        aktivieren.
      </p>
      <h2 id="spotify">Spotify</h2>
      <p>
        Auf unseren Seiten sind Funktionen des Musik-Dienstes Spotify
        eingebunden. Anbieter ist die Spotify AB, Birger Jarlsgatan 61, 113 56
        Stockholm in Schweden. Die Spotify PlugIns erkennen Sie an dem grünen
        Logo auf unserer Seite. Eine Übersicht über die Spotify-PlugIns finden
        Sie unter
        <a href="https://developer.spotify.com">
          https://developer.spotify.com
        </a>
      </p>
      <p>
        Dadurch kann beim Besuch unserer Seiten über das Plugin eine direkte
        Verbindung zwischen Ihrem Browser und dem Spotify-Server hergestellt
        werden. Spotify erhält dadurch die Information, dass Sie mit Ihrer
        IP-Adresse unsere Seite besucht haben. Wenn Sie den Spotify Button
        anklicken während Sie in Ihrem Spotify-Account eingeloggt sind, können
        Sie die Inhalte unserer Seiten auf Ihrem Spotify Profil verlinken.
        Dadurch kann Spotify den Besuch unserer Seiten Ihrem Benutzerkonto
        zuordnen.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
        Spotify:
        <a href="https://www.spotify.com/de/legal/privacy-policy/">
          https://www.spotify.com/de/legal/privacy-policy/
        </a>
      </p>
      <p>
        Wenn Sie nicht wünschen, dass Spotify den Besuch unserer Seiten Ihrem
        Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
        Spotify-Benutzerkonto aus.
      </p>
      <h2 id="xing">XING</h2>
      <p>
        Unsere Website nutzt Funktionen des Netzwerks XING. Anbieter ist die
        XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland. Bei jedem
        Abruf einer unserer Seiten, die Funktionen von XING enthält, wird eine
        Verbindung zu Servern von XING hergestellt. Eine Speicherung von
        personenbezogenen Daten erfolgt dabei nach unserer Kenntnis nicht.
        Insbesondere werden keine IP-Adressen gespeichert oder das
        Nutzungsverhalten ausgewertet.
      </p>
      <p>
        Weitere Information zum Datenschutz und dem XING Share-Button finden Sie
        in der Datenschutzerklärung von XING unter:{' '}
        <a href="https://www.xing.com/app/share?op=data_protection">
          https://www.xing.com/app/share?op=data_protection
        </a>
      </p>
      <h2 id="tumblr">Tumblr</h2>
      <p>
        Unsere Seiten nutzen Schaltflächen des Dienstes Tumblr. Anbieter ist die
        Tumblr, Inc., 35 East 21st St, 10th Floor, New York, NY 10010, USA.
        Diese Schaltflächen ermöglichen es Ihnen, einen Beitrag oder eine Seite
        bei Tumblr zu teilen oder dem Anbieter bei Tumblr zu folgen. Wenn Sie
        eine unserer Websites mit Tumblr-Button aufrufen, baut der Browser eine
        direkte Verbindung mit den Servern von Tumblr auf. Wir haben keinen
        Einfluss auf den Umfang der Daten, die Tumblr mit Hilfe dieses Plugins
        erhebt und übermittelt. Nach aktuellem Stand werden die IP-Adresse des
        Nutzers sowie die URL der jeweiligen Website übermittelt.
      </p>
      <p>
        Weitere Informationen hierzu finden sich in der Datenschutzerklärung von
        Tumblr unter:
        <a href="https://www.tumblr.com/policy/de/privacy">
          https://www.tumblr.com/policy/de/privacy
        </a>
        .
      </p>
      <h2 id="youtube">YouTube</h2>
      <p>
        Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube.
        Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno,
        CA 94066, USA. Wenn Sie eine unserer mit einem YouTube- Plugin
        ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von
        YouTube hergestellt. Dabei wird dem Youtube-Server mitgeteilt, welche
        unserer Seiten Sie besucht haben.
      </p>
      <p>
        Wenn Sie in Ihrem YouTube-Account eingeloggt sind ermöglichen Sie
        YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen.
        Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube- Account
        ausloggen.
      </p>
      <p>
        Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der
        Datenschutzerklärung von YouTube unter:{' '}
        <a href="https://www.google.de/intl/de/policies/privacy">
          https://www.google.de/intl/de/policies/privacy
        </a>
      </p>
      <h2 id="kommentarfunktion-auf-dieser-website">
        Kommentarfunktion auf dieser Website
      </h2>
      <p>
        Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar
        auch Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre
        E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte
        Nutzername gespeichert.
      </p>
      <p>Speicherung der IP Adresse</p>
      <p>
        Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die
        Kommentare verfassen. Da wir Kommentare auf unserer Seite nicht vor der
        Freischaltung prüfen, benötigen wir diese Daten, um im Falle von
        Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser
        vorgehen zu können.
      </p>
      <h2 id="ssl-verschlüsselung">SSL-Verschlüsselung</h2>
      <p>
        Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
        Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol
        in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an
        uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h2 id="recht-auf-auskunft-löschung-sperrung">
        Recht auf Auskunft, Löschung, Sperrung
      </h2>
      <p>
        Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre
        gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und
        den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung,
        Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum
        Thema personenbezogene Daten können Sie sich jederzeit unter der im
        Impressum angegebenen Adresse an uns wenden.
      </p>
      <h2 id="widerspruch-werbe-mails">Widerspruch Werbe-Mails</h2>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <p>Quelle: eRecht24</p>
    </Layout>
  );
};

export default Imprint;
